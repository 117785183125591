import type { Session, SessionShow } from "@/app/lib/session";
import { BaseShows, ShowTicketPrices } from "@/app/api/shows";
import { Cent } from "@/app/lib/currency";
import { ShowId } from "@/app/api/schema";

export function sumOfItemsForShow(show: SessionShow | undefined): number {
  if (show === undefined) {
    return 0;
  }
  return show.regular + show.discounted + show.wheelchair + show.company;
}

export function sumOfPricesForShow(
  show: SessionShow | undefined,
  ticketPrices: ShowTicketPrices,
): Cent {
  if (show === undefined) {
    return 0 as Cent;
  }

  return (ticketPrices.regular * show.regular +
    ticketPrices.discounted * show.discounted +
    ticketPrices.wheelchair * show.wheelchair +
    ticketPrices.company * show.company) as Cent;
}

export function showsObjectToList<TShow extends Record<string, unknown>>(
  shows: Record<string, TShow>,
): (TShow & { showId: string })[] {
  return Object.entries(shows).map(([id, show]) => ({
    ...show,
    showId: id,
  }));
}

export function sumOfItemsForCart(session: Pick<Session, "shows">): number {
  return showsObjectToList(session.shows).reduce(
    (acc, show) => sumOfItemsForShow(show) + acc,
    0,
  );
}

export function sumOfPricesForCart(
  session: Pick<Session, "shows">,
  ticketPrices: Record<string, ShowTicketPrices>,
): Cent {
  return showsObjectToList(session.shows).reduce(
    (acc, show) => sumOfPricesForShow(show, ticketPrices[show.showId]) + acc,
    0,
  ) as Cent;
}

export function showIdsWithTicketsForCart(
  session: Pick<Session, "shows">,
): ShowId[] {
  const showIds: ShowId[] = [];
  for (const show of showsObjectToList(session.shows)) {
    if (sumOfItemsForShow(show) > 0) {
      showIds.push(show.showId);
    }
  }
  return showIds;
}

export function countShowsWithTicketsForCart(session: Pick<Session, "shows">) {
  return showIdsWithTicketsForCart(session).length;
}

export function isCartEmpty(session: Pick<Session, "shows">): boolean {
  return sumOfItemsForCart(session) === 0;
}

export function ticketPricesFromShows(shows: BaseShows) {
  return Object.fromEntries(
    Object.entries(shows).map(([showId, show]) => [
      showId,
      {
        regular: show.categories.regular.price,
        discounted: show.categories.discounted.price,
        wheelchair: show.categories.wheelchair.price,
        company: show.categories.company.price,
      },
    ]),
  ) as {
    [key: string]: ShowTicketPrices;
  };
}
