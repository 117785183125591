"use client";

import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import type { IvvSession, Session, SessionInfo } from "@/app/lib/session";
import type { ShowTicketPrices } from "@/app/api/shows";
import { Cent } from "@/app/lib/currency";
import { ShowId } from "@/app/api/schema";
import { usePathname } from "next/navigation";
import {
  showIdsWithTicketsForCart,
  sumOfItemsForCart,
  sumOfPricesForCart,
} from "@/app/lib/utils";

export interface Props extends PropsWithChildren {
  initialSession: Session;
  processingFee: Cent;
  ticketPrices: {
    [key in ShowId]: ShowTicketPrices;
  };
}

export type FooterStateAction =
  | {
      type: "FULL_SESSION_UPDATE";
      session: Session;
    }
  | {
      type: "SET_INFO_VALUE";
      key:
        | "name"
        | "shippingAddress.street"
        | "shippingAddress.additionalLine"
        | "shippingAddress.postalCode"
        | "shippingAddress.city"
        | "shippingType";
      value: string;
    }
  | {
      type: "SET_COUNTS_FOR_SHOW";
      showId: ShowId;
      regular: number;
      discounted: number;
      wheelchair: number;
      company: number;
    };

export type FooterState = {
  mode: "regular" | "ivv";
  addressLabel: string;
  footerShown: boolean;
  envelopeOpen: boolean;
  currentStep: "date" | "ticket" | "info" | "success";
  processingFeeInfo: boolean;
  tickets: number;
  selectedShows: ShowId[];
  price: Cent;
};

type InternalState = {
  mode: "regular" | "ivv";
  shows: Session["shows"];
  info: SessionInfo | IvvSession["info"];
};

export function regularSessionInfoToAddressLabel(info: SessionInfo): string {
  return (
    info.name +
    "\n" +
    (info.shippingAddress.additionalLine.trim() !== ""
      ? info.shippingAddress.additionalLine + "\n"
      : "") +
    info.shippingAddress.street +
    "\n" +
    info.shippingAddress.postalCode +
    " " +
    info.shippingAddress.city
  );
}

export function ivvSessionInfoToAddressLabel(info: IvvSession["info"]): string {
  if (info.name.trim().length > 0) {
    return info.name + "\nAbholung in der Probe";
  } else {
    return "";
  }
}

function sessionToState(session: Session): InternalState {
  return {
    mode: session.mode,
    shows: session.shows,
    info: session.info,
  };
}

const reducer = function (
  state: InternalState,
  action: FooterStateAction,
): InternalState {
  switch (action.type) {
    case "FULL_SESSION_UPDATE":
      return {
        ...state,
        ...sessionToState(action.session),
      };
    case "SET_INFO_VALUE":
      switch (action.key) {
        case "shippingType":
          return {
            ...state,
            info: {
              ...state.info,
              shippingType: ["delivery", "rehearsal", "boxoffice"].includes(
                action.value,
              )
                ? (action.value as "delivery" | "rehearsal" | "boxoffice")
                : state.info.shippingType,
            },
          };
        case "name":
          return {
            ...state,
            info: {
              ...state.info,
              name: action.value,
            },
          };
        case "shippingAddress.street":
        case "shippingAddress.postalCode":
        case "shippingAddress.city":
          if (state.mode !== "regular") {
            return state;
          }

          const key = action.key.slice("shippingAddress.".length);

          return {
            ...state,
            info: {
              ...state.info,
              shippingAddress: {
                // @ts-expect-error
                ...(state.info.shippingAddress ?? {}),
                [key]: action.value,
              },
            },
          };
        default:
          return state;
      }
    case "SET_COUNTS_FOR_SHOW":
      return {
        ...state,
        shows: {
          ...state.shows,
          [action.showId]: {
            ...(state.shows[action.showId] ?? {}),
            regular: action.regular,
            discounted: action.discounted,
            wheelchair: action.wheelchair,
            company: action.company,
          },
        },
      };
  }
};

export const FooterStateContext = createContext<FooterState>({
  mode: "regular",
  addressLabel: "",
  footerShown: false,
  envelopeOpen: false,
  currentStep: "date",
  processingFeeInfo: true,
  tickets: 0,
  selectedShows: [],
  price: 0 as Cent,
});
export const FooterStateActionDispatchContext = createContext<
  Dispatch<FooterStateAction>
>(() => {});

// TODO: This should probably receive only a stripped down part of the session
export const FooterStatePageLoadUpdater: FC<{ session: Session }> = function (
  props,
) {
  const dispatch = useContext(FooterStateActionDispatchContext);

  // TODO: This causes problems with stale data
  //    WHY IS THERE EVEN STALE DATA, GOD DAMN' IT?

  useEffect(() => {
    dispatch({
      type: "FULL_SESSION_UPDATE",
      session: props.session,
    });
  }, [props.session, dispatch]);

  return null;
};

const pathRegex = /(?:\/intern)?(?:\/([\w-]+))?(?:\/.*)?/;

function pathnameToSegment(
  pathname: string,
): [
  "regular" | "ivv",
  "home" | "show" | "checkout" | "status" | "login" | "other",
] {
  const mode = pathname.startsWith("/intern") ? "ivv" : "regular";
  const match = pathRegex.exec(pathname);
  if (!match || match.length === 0) {
    return [mode, "other"];
  } else {
    const _segment = match[1];
    return [
      mode,
      _segment === undefined
        ? "home"
        : _segment === "show"
          ? "show"
          : _segment === "kasse"
            ? "checkout"
            : _segment === "bestell-status"
              ? "status"
              : _segment === "login"
                ? "login"
                : "other",
    ];
  }
}

export const FooterStateProvider: FC<Props> = function (props) {
  const pathname = usePathname();
  const [state, dispatch] = useReducer(
    reducer,
    sessionToState(props.initialSession),
  );

  const _state = useMemo(() => {
    const tickets = sumOfItemsForCart(state);
    const showIds = showIdsWithTicketsForCart(state);
    const _price = sumOfPricesForCart(state, props.ticketPrices);
    const price =
      _price > 0
        ? ((_price +
            (state.mode === "regular" && state.info.shippingType !== "rehearsal"
              ? props.processingFee
              : 0)) as Cent)
        : (0 as Cent);

    const [, segment] = pathnameToSegment(pathname);

    const footerShown =
      (segment === "home" && tickets > 0) ||
      segment === "show" ||
      segment === "checkout";

    const opened =
      segment === "home"
        ? tickets > 0
        : segment === "show"
          ? true
          : segment === "checkout"
            ? false
            : false;

    const currentStep: FooterState["currentStep"] =
      segment === "home"
        ? "date"
        : segment === "show"
          ? "ticket"
          : segment === "checkout"
            ? "info"
            : "success";

    const addressLabel =
      state.mode === "regular"
        ? // @ts-expect-error Not validated, but also highly unlikely to be wrong
          regularSessionInfoToAddressLabel(state.info)
        : ivvSessionInfoToAddressLabel(state.info);

    return {
      mode: state.mode,
      addressLabel,
      footerShown,
      envelopeOpen: opened,
      currentStep,
      processingFeeInfo:
        state.mode === "regular" &&
        props.processingFee > 0 &&
        state.info.shippingType !== "rehearsal",
      tickets,
      selectedShows: showIds,
      price,
    };
  }, [pathname, state, props.processingFee, props.ticketPrices]);

  return (
    <FooterStateActionDispatchContext.Provider value={dispatch}>
      <FooterStateContext.Provider value={_state}>
        {props.children}
      </FooterStateContext.Provider>
    </FooterStateActionDispatchContext.Provider>
  );
};
